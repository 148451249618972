<template>
  <div style="overflow-y: auto">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>商户资料</el-breadcrumb-item>
        <el-breadcrumb-item>修改</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div style="padding: 50px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-row>
          <el-col :span="23">
            <el-form-item label="LOGO：" prop="pic">
              <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="httpRequest">
                <img v-if="ruleForm.pic" :src="'http://192.168.3.3' + ruleForm.pic" class="avatar" />
                <el-button size="small" type="primary" class="uploadBtu">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称：" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="店铺简称：" prop="store">
              <el-input v-model="ruleForm.store"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="负责人：" prop="principal">
              <el-input v-model="ruleForm.principal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客服电话：" prop="serviceTel">
              <el-input v-model="ruleForm.serviceTel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row4">
          <el-col class="row4-left" :span="9">
            <el-form-item label="城市" required>
              <!-- <el-col :span="6">
                <el-form-item prop="city1">
                  <el-select
                    v-model="ruleForm.city1"
                    clearable
                    placeholder="请选择"
                    @focus="city1Focus"
                    @change="city1Change"
                    class="row4-left-select"
                    size="mini"
                  >
                    <el-option
                      v-for="item in ruleForm.cities1"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="ruleForm.city1 != ''">
                <el-form-item prop="city2">
                  <el-select
                    v-model="ruleForm.city2"
                    clearable
                    placeholder="请选择"
                    @focus="city2Focus"
                    @change="city2Change"
                    class="row4-left-select"
                    size="mini"
                  >
                    <el-option
                      v-for="item in ruleForm.cities2"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" v-if="ruleForm.city2 != ''">
                <el-form-item prop="city3">
                  <el-select
                    v-model="ruleForm.city3"
                    clearable
                    placeholder="请选择"
                    @focus="city3Focus"
                    class="row4-left-select"
                    size="mini"
                  >
                    <el-option
                      v-for="item in ruleForm.cities3"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <span>{{ location.join("") }} </span>
              <el-cascader style="width:100px" placeholder="请选择地区" :options="options" v-model="location"
                @change="ruleForm.city1 = location[0]; ruleForm.city2 = location[1]; ruleForm.city3 = location[2]" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="绑定电话：">
              <div>{{ ruleForm.bindPhone }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="地址：" prop="address">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="默认配送方式" prop="deliverMethod">
              <el-select v-model="ruleForm.deliverMethod" placeholder="请选择">
                <el-option v-for="item in ruleForm.deliverMethods" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="上午：" prop="amUndeliverTime">
              <el-select v-model="ruleForm.amUndeliverTime" placeholder="请选择" size="mini">
                <el-option v-for="item in ruleForm.amUndeliverTimes" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="amDays">
              <el-select v-model="ruleForm.amDays" placeholder="请选择" size="mini">
                <el-option v-for="item in ruleForm.amDayses" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="amDeliverTime">
              <el-select v-model="ruleForm.amDeliverTime" placeholder="请选择" size="mini">
                <el-option v-for="item in ruleForm.amDeliverTimes" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="下午：" prop="pmUndeliverTime">
              <el-select v-model="ruleForm.pmUndeliverTime" placeholder="请选择" size="mini">
                <el-option v-for="item in ruleForm.pmUndeliverTimes" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="pmDays">
              <el-select v-model="ruleForm.pmDays" placeholder="请选择" size="mini">
                <el-option v-for="item in ruleForm.pmDayses" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="pmDeliverTime">
              <el-select v-model="ruleForm.pmDeliverTime" placeholder="请选择" size="mini">
                <el-option v-for="item in ruleForm.pmDeliverTimes" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="即将超时提醒：" prop="reminderTime">
              <el-input v-model="ruleForm.reminderTime"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="9">
            <el-form-item label="交易佣金（%）：" prop="tardBrokerage">
              <el-input v-model="ruleForm.tardBrokerage"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="收单佣金（%）：" prop="collectBrokerage">
              <el-input v-model="ruleForm.collectBrokerage"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="9">
            <el-form-item label="提现手续费（%）：" prop="withdarwService">
              <el-input v-model="ruleForm.withdarwService"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="最低手续费：" prop="lowestService">
              <el-input v-model="ruleForm.lowestService"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="9">
            <el-form-item label="封顶手续费：" prop="highestService">
              <el-input v-model="ruleForm.highestService"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="状态：" prop="highestService">
              <el-radio-group v-model="ruleForm.status">
                <el-radio label="待审核">待审核</el-radio>
                <el-radio label="已审核">已审核</el-radio>
                <el-radio label="已关闭">已关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="区域：">
              <el-input v-model="ruleForm.region"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="业务经理：">
              <el-input v-model="ruleForm.businessManager"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系电话：">
              <el-input v-model="ruleForm.contactNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  props: ["data"],
  data() {
    return {
      location: [],
      options: pcaTextArr,
      ruleForm: {
        pic: "",
        name: "",
        store: "",
        principal: "",
        serviceTel: "",
        city1: "",
        city2: "",
        city3: "",
        cities1: [],
        cities2: [],
        cities3: [],
        address: "",
        deliverMethod: "",
        bindPhone: "",
        deliverMethods: [
          { label: "全国普通快递", value: 0 },
          { label: "只限开通城市", value: 1 },
        ],

        amUndeliverTime: "",
        amUndeliverTimes: [
          { label: "8点前下单", value: "8点前下单" },
          { label: "9点前下单", value: "9点前下单" },
          { label: "10点前下单", value: "10点前下单" },
          { label: "11点前下单", value: "11点前下单" },
          { label: "12点前下单", value: "12点前下单" },
        ],
        amDays: "",
        amDayses: [
          { label: "今天送", value: "今天送" },
          { label: "明天送", value: "明天送" },
          { label: "后天送", value: "后天送" },
          { label: "第三天送", value: "第三天送" },
        ],
        amDeliverTime: "",
        amDeliverTimes: [
          { label: "30分钟内送达", value: "30分钟内送达" },
          { label: "1小时内送达", value: "1小时内送达" },
          { label: "1.5小时内送达", value: "1.5小时内送达" },
          { label: "2小时内送达", value: "2小时内送达" },
          { label: "2.5小时内送达", value: "2.5小时内送达" },
          { label: "3小时内送达", value: "3小时内送达" },
          { label: "4小时内送达", value: "4小时内送达" },
          { label: "当日内送达", value: "当日内送达" },
        ],
        pmUndeliverTime: "",
        pmUndeliverTimes: [
          { label: "1点前下单", value: "1点前下单" },
          { label: "2点前下单", value: "2点前下单" },
          { label: "3点前下单", value: "3点前下单" },
          { label: "4点前下单", value: "4点前下单" },
          { label: "5点前下单", value: "5点前下单" },
          { label: "6点前下单", value: "6点前下单" },
          { label: "7点前下单", value: "7点前下单" },
          { label: "8点前下单", value: "8点前下单" },
          { label: "9点前下单", value: "9点前下单" },
          { label: "10点前下单", value: "10点前下单" },
          { label: "11点前下单", value: "11点前下单" },
          { label: "12点前下单", value: "12点前下单" },
        ],
        pmDays: "",
        pmDayses: [
          { label: "今天送", value: "今天送" },
          { label: "明天送", value: "明天送" },
          { label: "后天送", value: "后天送" },
          { label: "第三天送", value: "第三天送" },
        ],
        pmDeliverTime: "",
        pmDeliverTimes: [
          { label: "30分钟内送达", value: "30分钟内送达" },
          { label: "1小时内送达", value: "1小时内送达" },
          { label: "1.5小时内送达", value: "1.5小时内送达" },
          { label: "2小时内送达", value: "2小时内送达" },
          { label: "2.5小时内送达", value: "2.5小时内送达" },
          { label: "3小时内送达", value: "3小时内送达" },
          { label: "4小时内送达", value: "4小时内送达" },
          { label: "当日内送达", value: "当日内送达" },
        ],
        reminderTime: "",
        tardBrokerage: "",
        collectBrokerage: "",
        withdarwService: "",
        lowestService: "",
        highestService: "",
        status: "",
        region: "",
        businessManager: "",
        contactNumber: "",
      },
      rules: {
        pic: [{ required: true, message: "请添加LOGO", trigger: "blur" }],
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        store: [{ required: true, message: "请输入店铺简称", trigger: "blur" }],
        city1: [{ required: true, message: "必填字段", trigger: "blur" }],
        city2: [{ required: true, message: "必填字段", trigger: "blur" }],
        deliverMethod: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        amUndeliverTime: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        amDays: [{ required: true, message: "必填字段", trigger: "blur" }],
        amDeliverTime: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        pmUndeliverTime: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        pmDays: [{ required: true, message: "必填字段", trigger: "blur" }],
        pmDeliverTime: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        reminderTime: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        tardBrokerage: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        collectBrokerage: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        withdarwService: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        lowestService: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        highestService: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        status: [{ required: true, message: "必填字段", trigger: "blur" }],
      },
      myKey: "WGOBZ-JTR35-ACLIY-IGOU6-X2L63-XGFP3",
      id: "",
    };
  },

  mounted() {
    console.log(this.$props.data);
    const data = this.$props.data;
    this.ruleForm.pic = data.logo;
    this.id = data.id;
    this.ruleForm.principal = data.person_charge;
    this.ruleForm.city1 = data.province;
    this.ruleForm.city2 = data.city;
    this.ruleForm.city3 = data.county;
    this.ruleForm.bindPhone = data.bind_phone;
    this.ruleForm.address = data.location;
    this.ruleForm.deliverMethod = data.deliver_method;
    this.ruleForm.amUndeliverTime = data.order_signing_time.am.undeliverTime;
    this.ruleForm.amDays = data.order_signing_time.am.days;
    this.ruleForm.amDeliverTime = data.order_signing_time.am.deliverTime;
    this.ruleForm.pmUndeliverTime = data.order_signing_time.pm.undeliverTime;
    this.ruleForm.pmDays = data.order_signing_time.pm.days;
    this.ruleForm.pmDeliverTime = data.order_signing_time.pm.deliverTime;
    this.ruleForm.reminderTime = data.reminder_time;
    this.ruleForm.tardBrokerage = data.tard_brokerage;
    this.ruleForm.collectBrokerage = data.collect_brokerage;
    this.ruleForm.withdarwService = data.withdarw_service_charge;
    this.ruleForm.lowestService = data.lowest_service_charge;
    this.ruleForm.highestService = data.highest_service_charge;
    this.ruleForm.status = data.status;
    this.ruleForm.region = data.region;
    this.ruleForm.businessManager = data.business_manager;
    this.ruleForm.contactNumber = data.contact_number;
    this.ruleForm.name = data.enterprise_name;
    this.ruleForm.store = data.store_abbreviation;
    this.ruleForm.serviceTel = data.service_number;
  },
  computed: {},
  methods: {
    closeClick() {
      this.$emit("amClose");
    },
    submitForm(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          const order = {
            am: { undeliverTime: "", days: "", deliverTime: "" },
            pm: { undeliverTime: "", days: "", deliverTime: "" },
          };
          order.am.undeliverTime = this.ruleForm.amUndeliverTime;
          order.am.days = this.ruleForm.amDays;
          order.am.deliverTime = this.ruleForm.amDeliverTime;
          order.pm.undeliverTime = this.ruleForm.pmUndeliverTime;
          order.pm.days = this.ruleForm.pmDays;
          order.pm.deliverTime = this.ruleForm.pmDeliverTime;
          console.log(this.ruleForm.city1);
          console.log(this.ruleForm.city2);
          console.log(this.ruleForm.city3);
          let res = {
            id: this.id,
            logo: this.ruleForm.pic,
            person_charge: this.ruleForm.principal,
            province: this.ruleForm.city1,
            city: this.ruleForm.city2,
            county: this.ruleForm.city3,
            bind_phone: this.ruleForm.bindPhone,
            location: this.ruleForm.address,
            deliver_method: this.ruleForm.deliverMethod,
            order_signing_time: order,
            reminder_time: this.ruleForm.reminderTime,
            tard_brokerage: this.ruleForm.tardBrokerage,
            collect_brokerage: this.ruleForm.collectBrokerage,
            withdarw_service_charge: this.ruleForm.withdarwService,
            lowest_service_charge: this.ruleForm.lowestService,
            highest_service_charge: this.ruleForm.highestService,
            status: this.ruleForm.status,
            region: this.ruleForm.region,
            business_manager: this.ruleForm.businessManager,
            contact_number: this.ruleForm.contactNumber,
            enterprise_name: this.ruleForm.name,
            store_abbreviation: this.ruleForm.store,
            service_number: this.ruleForm.serviceTel,
          }
          console.log(res);
          this.$http
            .post("/client/commercial_owner/save", res)
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("amClose");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    httpRequest(file) {
      this.getBase64(file.file).then((res) => {
        this.$http
          .post("uploads_images/up", this.$qs.stringify({ file: res }))
          .then((ress) => {
            this.ruleForm.pic = ress.data.path;
          });
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    city1Focus() {
      if (this.ruleForm.cities1.length == 0) {
        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
          key: this.myKey,
          callbackName: "QQmap",
          output: "jsonp",
        }).then((res) => {
          console.log(res);
          res.result[0].forEach((item) => {
            this.ruleForm.cities1.push({ name: item.fullname, id: item.id });
          });
        });
      }
    },
    city2Focus() {
      if (
        this.ruleForm.cities2.length == 0 ||
        this.ruleForm.city1 != sessionStorage.getItem("city1")
      ) {
        sessionStorage.setItem("city1", this.ruleForm.city1);
        this.ruleForm.cities2 = [];
        this.ruleForm.cities1.forEach((item) => {
          if (item.name == this.ruleForm.city1) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.ruleForm.cities2.push({
                  name: item.fullname,
                  id: item.id,
                });
              });
            });
          }
        });
      }
    },
    city3Focus() {
      if (
        this.ruleForm.cities3.length == 0 ||
        this.ruleForm.city2 != sessionStorage.getItem("city2")
      ) {
        sessionStorage.setItem("city2", this.ruleForm.city2);
        this.ruleForm.cities3 = [];
        this.ruleForm.cities2.forEach((item) => {
          if (item.name == this.ruleForm.city2) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.ruleForm.cities3.push(item.fullname);
              });
            });
          }
        });
      }
    },
    city1Change() {
      this.ruleForm.city2 = "";
      this.ruleForm.city3 = "";
    },
    city2Change() {
      this.ruleForm.city3 = "";
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row4 {
  &-left {
    display: flex;
    align-items: center;

    &-select {
      width: 90px;
    }
  }
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
  pointer-events: none !important;
}

.row5 {
  transform: translateY(-10px);
}
</style>

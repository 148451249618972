<template>
  <div>
    <div v-if="isShow && !amendShow">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>商户资料</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload">
          <i style="font-size: 18px" class="el-icon-refresh-right"></i>
        </el-button>
      </div>

      <el-row style="padding: 20px 20px 0; white-space: nowrap">
        <el-col :span="6">
          <span>日期范围：</span>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-select v-model="option1" clearable filterable placeholder="状态">
            <el-option
              v-for="item in option1es"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="QueryContent"
            placeholder="输入要查询的内容"
            style="width: 70%; margin-right: 5px"
          ></el-input>
          <el-button
            type="success"
            size="mini"
            style="margin-left: 5px"
            @click="searchBtn"
          >
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">查询</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 20px; padding: 5px 0; border: 1px solid #eee">
        <el-col :span="2">
          <el-button type="primary" size="mini" @click="isShow = !isShow">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">新增企业</span>
            </div>
          </el-button>
        </el-col>
        <el-col :span="4">
          <el-button
            type="danger"
            size="mini"
            style="margin-left: 5px"
            @click="batchDelete"
          >
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </el-col>
      </el-row>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建日期"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="commercial_account"
            label="商户号"
          ></el-table-column>
          <el-table-column
            prop="enterprise_name"
            label="商户名称"
          ></el-table-column>
          <el-table-column
            prop="putaway"
            sortable
            label="上架商品数"
          ></el-table-column>
          <el-table-column
            prop="account_balance"
            sortable
            label="账户余额"
          ></el-table-column>
          <el-table-column prop="status" label="状态"></el-table-column>
          <el-table-column prop="city" label="区域"></el-table-column>
          <el-table-column label="管理" width="320">
            <template slot-scope="scope">
              <div
                style="display: flex; flex-wrap: wrap; justify-content: center"
              >
                <el-button plain @click="handleReset(scope.row)"
                  >重置密码</el-button
                >
                <el-button plain @click="handleSet(scope.row)">设置</el-button>
                <el-button plain @click="handlePay(scope.row)">充值</el-button>
                <el-button plain @click="handleDriver(scope.row)"
                  >司机</el-button
                >
                <el-button plain @click="handleInventory(scope.row)"
                  >库存</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="tableFinshNum"
              :current-page="page"
              @current-change="currentChange"
            ></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <add-Group @close="onClose" v-if="!isShow && !amendShow"></add-Group>
    <AmendGroup @amClose="amClose" v-if="amendShow" :data="data" />
  </div>
</template>
<script>
import addGroup from "./childrens/merchantInformation_addGroup/addGroup.vue";
import { dateYear } from "../../../../until/utilse";
import AmendGroup from "./childrens/merchantInformation_addGroup/amendGroup.vue";
export default {
  components: {
    addGroup,
    AmendGroup,
  },
  data() {
    return {
      date: "",

      option1: "",
      option1es: ["待审核", "已审核", "已关闭"],
      QueryContent: "",

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,

      isShow: true,
      page: 1,
      length: 10,
      amendShow: false,
      data: "",
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
    this.getTabData();
  },
  computed: {},
  methods: {
    reload() {
      this.getTabData();
    },
    onClose(data) {
      this.isShow = data;
      this.getTabData();
    },
    amClose() {
      this.amendShow = false;
      this.getTabData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTabData() {
      this.$http
        .post("/Client/commercial_owner/lst", {
          currentLength: this.length,
          currentPage: this.page,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleReset() {},
    handleSet() {},
    handlePay() {},
    handleDriver() {},
    handleInventory() {},

    handleEdit(row) {
      this.data = row;
      this.amendShow = true;
    },
    batchDelete() {
      if (this.multipleSelection.length > 0) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            const arr = [];
            this.multipleSelection.forEach((item) => {
              arr.push(item.id);
            });
            this.$http
              .post("/Client/commercial_owner/bacthDel", { idArr: arr })
              .then((res) => {
                if (res.data.status == "success") {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.getTabData();
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Client/commercial_owner/del", { id: row.id })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getTabData();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    searchBtn() {
      this.$http
        .post("/Client/commercial_owner/search", {
          firstTime: dateYear(this.date[0]),
          lastTime: dateYear(this.date[1]),
          status: this.option1,
          search: this.QueryContent,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabData();
      },
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}
.el-button + .el-button {
  margin-left: 0;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>

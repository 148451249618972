<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>商户资料</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>

    <div style="padding: 50px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-row>
          <el-col :span="24">
            <el-form-item label="注册手机：" prop="registerPhone">
              <el-input v-model="ruleForm.registerPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-form-item label="LOGO：" prop="pic">
              <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="httpRequest">
                <img v-if="ruleForm.pic" :src="'http://192.168.3.3' + ruleForm.pic" class="avatar" />
                <el-button size="small" type="primary" class="uploadBtu">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称：" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="店铺简称：" prop="store">
              <el-input v-model="ruleForm.store"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="height: 51px">
          <el-form-item label="城市" required>
            <!-- <el-col :span="2">
              <el-form-item prop="city1">
                <el-select
                  v-model="ruleForm.city1"
                  clearable
                  placeholder="请选择"
                  @focus="city1Focus"
                  @change="city1Change"
                >
                  <el-option
                    v-for="item in ruleForm.cities1"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2" v-if="ruleForm.city1 != ''">
              <el-form-item prop="city2">
                <el-select
                  v-model="ruleForm.city2"
                  clearable
                  placeholder="请选择"
                  @focus="city2Focus"
                  @change="city2Change"
                >
                  <el-option
                    v-for="item in ruleForm.cities2"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2" v-if="ruleForm.city2 != ''">
              <el-form-item prop="city3">
                <el-select
                  v-model="ruleForm.city3"
                  clearable
                  placeholder="请选择"
                  @focus="city3Focus"
                >
                  <el-option
                    v-for="item in ruleForm.cities3"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <span>{{ location.join("") }} </span>
            <el-cascader style="width:100px" placeholder="请选择地区" :options="options" v-model="location"
              @change="ruleForm.city1 = location[0]; ruleForm.city2 = location[1]; ruleForm.city3 = location[2]" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="地址：" prop="address">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="负责人：" prop="principal">
              <el-input v-model="ruleForm.principal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客服电话：" prop="servicePhone">
              <el-input v-model="ruleForm.servicePhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="交易佣金(%)：" prop="deal">
              <el-input v-model="ruleForm.deal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收单佣金(%)：" prop="receipt">
              <el-input v-model="ruleForm.receipt"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="提现手续费(%)：" prop="withdraw">
              <el-input v-model="ruleForm.withdraw"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="最低手续费：" prop="lowest">
              <el-input v-model="ruleForm.lowest"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="封顶手续费：" prop="capping">
              <el-input v-model="ruleForm.capping"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="状态：" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio label="待审核"></el-radio>
            <el-radio label="已审核"></el-radio>
            <el-radio label="已关闭"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  data() {
    return {
      options: pcaTextArr,
      location: [],
      ruleForm: {
        registerPhone: "",
        pic: "",
        name: "",
        store: "",
        city1: "",
        city2: "",
        city3: "",
        cities1: [],
        cities2: [],
        cities3: [],
        address: "",
        principal: "",
        servicePhone: "",
        deal: "0.6",
        receipt: "0.6",
        withdraw: "0",
        lowest: "1",
        capping: "0",
        status: "已审核",
      },
      rules: {
        registerPhone: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        pic: [
          {
            required: true,
            message: "必填字段",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        store: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        city1: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        city2: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        deal: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        receipt: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        withdraw: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        lowest: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        capping: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
      },
      myKey: "WGOBZ-JTR35-ACLIY-IGOU6-X2L63-XGFP3",
    };
  },
  mounted() { },
  computed: {},
  methods: {
    closeClick() {
      this.$emit("close", true);
    },

    handleAvatarSuccess(res, file) {
      this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const subObj = {
            logo: this.ruleForm.pic,
            enterprise_name: this.ruleForm.name,
            store_abbreviation: this.ruleForm.store,
            person_charge: this.ruleForm.principal,
            service_number: this.ruleForm.servicePhone,
            province: this.ruleForm.city1,
            city: this.ruleForm.city2,
            county: this.ruleForm.city3,
            bind_phone: this.ruleForm.registerPhone,
            location: this.ruleForm.address,
            tard_brokerage: this.ruleForm.deal,
            collect_brokerage: this.ruleForm.receipt,
            withdarw_service_charge: this.ruleForm.withdraw,
            lowest_service_charge: this.ruleForm.lowest,
            highest_service_charge: this.ruleForm.capping,
            status: this.ruleForm.status,
          };
          this.$http
            .post("/client/commercial_owner/save", subObj)
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    httpRequest(file) {
      this.getBase64(file.file).then((res) => {
        this.$http
          .post("uploads_images/up", this.$qs.stringify({ file: res }))
          .then((ress) => {
            this.ruleForm.pic = ress.data.path;
          });
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    city1Focus() {
      if (this.ruleForm.cities1.length == 0) {
        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
          key: this.myKey,
          callbackName: "QQmap",
          output: "jsonp",
        }).then((res) => {
          console.log(res);
          res.result[0].forEach((item) => {
            this.ruleForm.cities1.push({ name: item.fullname, id: item.id });
          });
        });
      }
    },
    city2Focus() {
      if (
        this.ruleForm.cities2.length == 0 ||
        this.ruleForm.city1 != sessionStorage.getItem("city1")
      ) {
        sessionStorage.setItem("city1", this.ruleForm.city1);
        this.ruleForm.cities2 = [];
        this.ruleForm.cities1.forEach((item) => {
          if (item.name == this.ruleForm.city1) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.ruleForm.cities2.push({
                  name: item.fullname,
                  id: item.id,
                });
              });
            });
          }
        });
      }
    },
    city3Focus() {
      if (
        this.ruleForm.cities3.length == 0 ||
        this.ruleForm.city2 != sessionStorage.getItem("city2")
      ) {
        sessionStorage.setItem("city2", this.ruleForm.city2);
        this.ruleForm.cities3 = [];
        this.ruleForm.cities2.forEach((item) => {
          if (item.name == this.ruleForm.city2) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.ruleForm.cities3.push(item.fullname);
              });
            });
          }
        });
      }
    },
    city1Change(data) {
      console.log(data);
      this.ruleForm.city2 = "";
      this.ruleForm.city3 = "";
    },
    city2Change() {
      this.ruleForm.city3 = "";
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
  pointer-events: none !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}

/deep/ .el-select .el-input__inner {
  padding-right: 0 !important;
}

/deep/ .el-input__inner {
  width: 120% !important;
}
</style>
